<template>
  <section>
    <v-container>
      <v-row>
        <v-col
          class="my-10"
          :align="$vuetify.breakpoint.mobile ? 'center' : 'left'"
          cols="12"
          md="6"
        >
          <v-img
            :src="$store.state.imagePath + print.image_path"
            max-width="80%"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <div>
            <Title :title="print.title" />
          </div>
          <div
            class="pb-8"
            v-html="inner_html"
          ></div>
          <!-- <p class="text-xl-h6"  :style="$vuetify.breakpoint.mobile ? '' : 'max-width: 80%'">
                       {{print.description}}
                    </p> -->
        </v-col>
      </v-row>
      <!-- <v-row class="mb-10">
                <v-col cols="12">
                    <Title title="Outras gravações" />
                </v-col>
                <v-col v-for="(card, idx) in prints" :key="idx" cols="3">
                    <PrintCard :card="card" />
                </v-col>
            </v-row> -->
    </v-container>
  </section>
</template>

<script>
export default {
  components: {
    Title: () => import('@/components/web/layout/widgets/Title'),
    // PrintCard: () => import('@/components/web/print/PrintCard')
  },
  computed: {
    inner_html() {
      return this.print.description // any raw html
    },
  },
  data: () => ({
    routes: [{ title: 'Gravações', route: '/print' }],
    prints: [],
    cards: [
      {
        src: require('@/assets/prints/img01.png'),
        title: 'Cromia',
        id: 1,
      },
      {
        src: require('@/assets/prints/img02.png'),
        title: 'Digital UV',
        id: 2,
      },
      {
        src: require('@/assets/prints/img03.png'),
        title: 'Etiqueta Resinada',
        id: 3,
      },
      {
        src: require('@/assets/prints/img04.png'),
        title: 'Hot Stamping',
        id: 4,
      },
      {
        src: require('@/assets/prints/img05.png'),
        title: 'Laser',
        id: 5,
      },
      {
        src: require('@/assets/prints/img06.png'),
        title: 'Off-set',
        id: 6,
      },
      {
        src: require('@/assets/prints/img07.png'),
        title: 'Relevo',
        id: 7,
      },
      {
        src: require('@/assets/prints/img08.png'),
        title: 'Silk Screen',
        id: 8,
      },
      {
        src: require('@/assets/prints/img09.png'),
        title: 'Sleeve',
        id: 9,
      },
      {
        src: require('@/assets/prints/img10.png'),
        title: 'Sublimação',
        id: 10,
      },
      {
        src: require('@/assets/prints/img11.png'),
        title: 'Tampografia',
        id: 11,
      },
      {
        src: require('@/assets/prints/img12.png'),
        title: 'Transfer',
        id: 12,
      },
    ],
    print: null,
  }),
  methods: {
    getPost() {
      this.$api
        .get(`prints/${this.$route.params.id}`)
        .then((res) => {
          this.print = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
    },
  },
  created() {
    this.getPost()
    this.$store.commit('setBreadcrumbs', this.routes)
  },
  // beforeUpdate() {
  //     this.getPost()
  // },
}
</script>

<style></style>
